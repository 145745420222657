import "./MobileOrientationOverlay.scss";

export default function MobileOrientationOverlay() {
    return (
        <div className="mobile-orientation-overlay">
            <div className="message">
                <div>
                    <div>mobile optimized version</div>
                    <div>coming soon</div>
                    <div>--------</div>
                    <div>in the meanwhile,</div>
                    <div>please change</div>
                    <div>your phone orientation</div>
                    <div>to portrait</div>
                </div>
                <div className="rotate-animation">{smartPhone_svg}</div>
            </div>
            <MobileUniversalFooter />
        </div>
    );
}

const smartPhone_svg = (
    <svg
        width="110"
        height="110"
        viewBox="0 0 110 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M77.9167 4.62921L32.0834 4.58337C27.0417 4.58337 22.9167 8.70837 22.9167 13.75V96.25C22.9167 101.292 27.0417 105.417 32.0834 105.417H77.9167C82.9584 105.417 87.0834 101.292 87.0834 96.25V13.75C87.0834 8.70837 82.9584 4.62921 77.9167 4.62921ZM77.9167 87.0834H32.0834V22.9167H77.9167V87.0834Z"
            fill="white"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M66.0781 54.4999C66.079 57.0128 65.217 59.4498 63.6364 61.4033C62.0557 63.3567 59.8523 64.7083 57.3945 65.2318C54.9368 65.7553 52.3738 65.4191 50.1343 64.2793C47.8948 63.1396 46.1145 61.2654 45.0912 58.9703L41.7007 60.3475C43.0448 63.4272 45.4082 65.9502 48.3936 67.4924C51.379 69.0346 54.8044 69.5019 58.0938 68.8157C61.3832 68.1296 64.3361 66.3319 66.456 63.7247C68.5759 61.1176 69.7336 57.8602 69.7344 54.4999C69.7345 51.4475 68.7796 48.4716 67.0034 45.9892C65.2273 43.5067 62.7189 41.6423 59.8299 40.657C56.9409 39.6718 53.816 39.6152 50.8932 40.4952C47.9704 41.3752 45.3961 43.1476 43.5312 45.5641V41.0937H39.875V50.8437L41.7031 52.6718H50.2344V49.0156H45.608C46.8148 46.9234 48.6784 45.288 50.9097 44.3633C53.1409 43.4385 55.615 43.2761 57.948 43.9011C60.281 44.5262 62.3425 45.9038 63.8125 47.8202C65.2826 49.7366 66.0789 52.0847 66.0781 54.4999V54.4999Z"
            fill="white"
        />
    </svg>
);
const vsig = "https://cdn.bmschoi.dev/kongee/assets/footer/vsig_white.png";
function MobileUniversalFooter() {
    return (
        <div className="mobile-universal-footer">
            <div className="left">
                <div className="left">
                    <img src={vsig} alt="vertical signature" />
                </div>
                <div className="right">
                    <div className="top">
                        <div>Sally</div>
                        <div>(Hyunji) Kim</div>
                    </div>
                    <div className="bottom">UX / UI DESIGN</div>
                </div>
            </div>
            <div className="divider"></div>
            <div className="right">
                <div className="quote">
                    Designed with lots and lots of Philtered Soul by Sally
                    (Hyunji) Kim. CRAFTED BY BRANDON CHOI.
                </div>
            </div>
        </div>
    );
}
