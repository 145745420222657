import "./styles.scss";
import Mouse from "./pages/mouse";
import Main from "./pages/main";
import Overlay from "./pages/loadingOverlay";
import MobileOrientationOverlay from "./components/MobileOrientationOverlay";
import { Helmet } from "react-helmet";
import { useLayoutEffect } from "react";
import { withOrientationChange } from "react-device-detect";

import { isMobile } from "react-device-detect";
//

let App = (props) => {
    const { isLandscape, isPortrait } = props;

    // console.log(isMobile, isPortrait);

    useLayoutEffect(() => {
        if (isMobile && isPortrait) {
            window.document.children[0].style = "font-size:1.3vw";
        } else if (isMobile) {
            window.document.children[0].style = "";
        }
    }, [isMobile, isPortrait]);

    return (
        <>
            <Helmet>
                {process.env.NODE_ENV === "production" ? (
                    <script
                        async
                        defer
                        data-website-id="4aaa6d88-2eeb-4adc-80bb-aa621d957738"
                        src="https://umami.bmschoi.dev/umami.js"
                    ></script>
                ) : (
                    <script
                        async
                        defer
                        data-website-id="985d71a1-971c-4b5b-8f3b-bf9faedc18a8"
                        src="https://umami.bmschoi.dev/umami.js"
                    ></script>
                )}
            </Helmet>
            <Mouse />
            <Main />
            <Overlay />
            {isMobile && isPortrait && <MobileOrientationOverlay />}
        </>
    );
};

App = withOrientationChange(App);

export default App;
